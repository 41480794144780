import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root',
})
export class storageService extends Storage {
  storage_name: string;
  constructor() {
    super();
    super.create();
    this.storage_name = 'octasignage/';
  }

  override async get(key: string) {
    let data = super.get(this.storage_name + key);
    return data;
  }
  override async set(key: string, value: any) {
    return super.set(this.storage_name + key, value);
  }
  override async remove(...keys: string[]) {
    keys.forEach(async (key: string) => {
      let res = await super.remove(this.storage_name + key);
    });
  }
  override async clear(): Promise<void> {
    await this.remove.apply(
      this,
      (await super.keys())
        .filter((key) => key.startsWith(this.storage_name))
        .map((key) => key.replace(this.storage_name, ''))
    );
  }
}
