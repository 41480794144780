import { Injectable } from '@angular/core';
import { storageService } from '../storage-service/storage.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoginData, RegisterData } from '@octasignage/validation';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  storage = new storageService();
  readonly dataSubject = new BehaviorSubject<void>(undefined);

  constructor() {}

  async isLoggedIn(): Promise<boolean> {
    return !!(await this.getToken());
  }
  async getToken() {
    return await this.storage.get('token');
  }
  async getRole(): Promise<'user' | 'admin' | 'super'> {
    return await this.storage.get('role');
  }
  async login(data: LoginData | RegisterData) {
    await this.storage.set('token', data.token);
    await this.storage.set('role', data.role);
    this.dataSubject.next();
  }
  async logout() {
    await this.storage.remove('token');
    await this.storage.remove('role');
  }
}
